import React, { useCallback, useState } from "react";
import { StyleSheet, TextInput, View, ScrollView } from "react-native";
import { z } from "zod";
import SelectDropdown from "react-native-select-dropdown";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import CustomTextInput from "../../../components/common/general/CustomTextInput/CustomTextInput";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import NewCustomAxioActionModal from "../UsersTabContainer/NewCustomAxioActionModal";
import Pill from "../../../components/common/Pill/Pill";
import { CourseTypes } from "./CoursesTable";
import ImageUpload from "../../../components/common/general/ImageUpload/ImageUpload";
import { CreateCoursePlaceholderMutation, useUploadCourseThumbnailMutation } from "../../../graphql/generated/graphql";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import AddCourseSecondStep from "./AddCourseSecondStep";
import CurrencyInput from "react-native-currency-input";
import DatePickerButton from "../../../components/general/DatePickerButton/DatePickerButton";
import { DatePickerModal } from "react-native-paper-dates";
import { useAppConfig } from "../../../AppConfigProvider";
import AddCourseDegreesStep from "./AddCourseDegreesStep";

const modals = {
  addConfirmation: {
    title: "Success!",
    ariaLabel: "Course Added Successfully Modal",
    warningText: "The course has been added. You can view the course by clicking the link below.",
    actionButtonText: "OK",
  },
  addError: {
    title: "Error!",
    ariaLabel: "Course Addition Error Modal",
    warningText: "An error occurred while adding the course. You may not have permission to add a course.",
    actionButtonText: "CLOSE",
  },
};

export enum AddCourseModalState {
  CLOSED = "CLOSED",
  ADD_CONFIRMATION = "ADD_CONFIRMATION",
  ADD_ERROR = "ADD_ERROR",
}

const courseSchema = z.object({
  courseName: z.string().min(1, "Course name is required"),
  courseType: z.enum(["Core", "General", "Elective"]) as z.ZodType<"Core" | "General" | "Elective">,
  degrees: z.array(z.object({ id: z.string(), name: z.string() })).optional(),
  thumbnail: z.string().optional(),
  description: z.string().optional(),
  objectives: z.string().optional(),
  targetAudience: z.string().optional(),
  cost: z.number().optional(),
  startDate: z.date().optional(),
});

type CourseFormData = z.infer<typeof courseSchema>;

type SubmitCourseFormData = Omit<CourseFormData, "degrees"> & {
  degrees?: string[];
};

interface AddCourseModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (formData: SubmitCourseFormData) => Promise<CreateCoursePlaceholderMutation>;
}

const AddCourseModal: React.FC<AddCourseModalProps> = ({ visible, onClose, onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isMedium } = useResponsiveScreen();
  const [modalState, setModalState] = useState({
    state: AddCourseModalState.CLOSED,
  });
  const { hasDegrees } = useAppConfig();
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [formStep, setFormStep] = useState<"first" | "hasDegrees" | "second">("first");
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    reset,
    trigger,
  } = useForm<CourseFormData>({
    resolver: zodResolver(courseSchema),
    defaultValues: {
      courseName: "",
      courseType: "Core",
      degrees: [],
      thumbnail: "",
      description: "",
      objectives: "",
      targetAudience: "",
      cost: 0,
      startDate: new Date(),
    },
  });

  const [uploadThumbnail] = useUploadCourseThumbnailMutation();

  const handleClose = () => {
    onClose();
    reset();
    setFormStep("first");
  };

  const handleFormSubmit = React.useCallback(
    async (formData: CourseFormData) => {
      try {
        setIsSubmitting(true);
        const degrees = formData.degrees.map((d) => d.id);

        // First create the course
        const response = await onSubmit({
          ...formData,
          degrees,
        });
        const result = response?.createCoursePlaceholder;

        if (!result) {
          setModalState({ state: AddCourseModalState.ADD_ERROR });
          setIsSubmitting(false);
          return;
        }

        if (result.success && formData.thumbnail && result.data?.[0]?.id) {
          // If course creation was successful and we have a thumbnail, upload it
          const base64Data = formData.thumbnail.split(",")[1];
          const contentType = formData.thumbnail.split(";")[0].split(":")[1];
          const size = Math.ceil((base64Data.length * 3) / 4);

          await uploadThumbnail({
            variables: {
              input: {
                image: base64Data,
                contentType,
                size,
                courseId: result.data[0].id,
              },
            },
          });
        }

        handleClose();
        if (result.success) {
          setModalState({ state: AddCourseModalState.ADD_CONFIRMATION });
        } else {
          setModalState({ state: AddCourseModalState.ADD_ERROR });
        }
        setIsSubmitting(false);
      } catch (error) {
        console.error("Error submitting form:", error);
        setModalState({ state: AddCourseModalState.ADD_ERROR });
        setIsSubmitting(false);
      }
    },
    [onSubmit, handleClose, uploadThumbnail]
  );

  const isFirstStep = formStep === "first";
  const isSecondStep = formStep === "second";
  const isDegreesStep = formStep === "hasDegrees";

  const handleNext = async () => {
    if (formStep === "first") {
      const isValid = await trigger(["courseName", "courseType"]);
      if (isValid) {
        if (hasDegrees) {
          setFormStep("hasDegrees");
        } else {
          setFormStep("second");
        }
      }
    } else if (formStep === "hasDegrees") {
      setFormStep("second");
    }
  };

  const handleBackOrCancel = () => {
    if (formStep === "second") {
      if (hasDegrees) {
        setFormStep("hasDegrees");
      } else {
        setFormStep("first");
      }
    } else if (formStep === "hasDegrees") {
      setFormStep("first");
    } else {
      handleClose();
    }
  };

  const onConfirmDateSelection = (params: { date: Date }) => {
    setIsDatePickerVisible(false);
    setValue("startDate", params.date);
  };

  return (
    <>
      <CustomModal
        visible={visible}
        onRequestClose={handleClose}
        title="Add a Course"
        exitFill="#6B7280"
        ariaLabel="Add course modal"
        style={{
          width: isMedium ? "50%" : "90%",
          maxHeight: "90vh",
        }}
        onShow={() => {}}
        showCloseButton={true}>
        <ScrollView style={styles.modalScrollView} showsVerticalScrollIndicator={true} bounces={false}>
          <View style={styles.container}>
            {isFirstStep && (
              <>
                <View style={styles.formGroup}>
                  <CustomText
                    text="Course Name"
                    size="s"
                    textType="text"
                    style={{ color: "#182230" }}
                    weight="ultraBold"
                  />
                  <CustomSpacing size="s" type="vertical" />
                  <Controller
                    control={control}
                    name="courseName"
                    render={({ field: { onChange, value } }) => (
                      <CustomTextInput
                        value={value}
                        onChangeText={onChange}
                        placeholder="Understanding Design Change Types"
                        style={styles.input}
                      />
                    )}
                  />
                  {errors?.courseName?.message && (
                    <CustomText size="xs" style={{ color: "#EF4444" }} text={errors.courseName?.message} />
                  )}
                </View>

                <View style={styles.formGroup}>
                  <CustomText
                    text="Select a Course Type"
                    size="s"
                    textType="text"
                    style={{ color: "#182230" }}
                    weight="ultraBold"
                  />
                  <CustomText text="Choose from Core, General, and Elective" size="xs" style={styles.helperText} />
                  <Controller
                    control={control}
                    name="courseType"
                    render={({ field: { onChange, value } }) => (
                      <SelectDropdown
                        data={Object.values(CourseTypes)}
                        defaultValue={CourseTypes[value]}
                        onSelect={(item) => onChange(item.name)}
                        buttonStyle={[styles.dropdown, errors.courseType && styles.dropdownError]}
                        buttonTextStyle={styles.dropdownText}
                        dropdownStyle={styles.dropdownList}
                        rowStyle={styles.dropdownRow}
                        rowTextStyle={styles.dropdownRowText}
                        defaultButtonText="Select course type"
                        buttonTextAfterSelection={(item) => item.name}
                        rowTextForSelection={(item) => item.name}
                        renderDropdownIcon={(isOpened) => (
                          <View style={[styles.dropdownIcon, isOpened && styles.dropdownIconUp]} />
                        )}
                        dropdownIconPosition="right"
                        renderCustomizedButtonChild={(selectedItem) => (
                          <View style={styles.dropdownButton}>
                            {selectedItem ? (
                              <>
                                <Pill leftIcon={selectedItem.icon} backgroundColor={selectedItem.color} />
                                <CustomText text={selectedItem.name} size="xs" style={styles.dropdownButtonText} />
                              </>
                            ) : (
                              <CustomText text="Select course type" size="xs" style={styles.dropdownPlaceholder} />
                            )}
                          </View>
                        )}
                        renderCustomizedRowChild={(item, index) => (
                          <View style={styles.dropdownRow}>
                            <Pill leftIcon={item.icon} backgroundColor={item.color} />
                            <CustomText text={item.name} size="xs" style={styles.dropdownRowText} />
                          </View>
                        )}
                      />
                    )}
                  />
                  {errors?.courseType?.message && (
                    <CustomText size="xs" style={{ color: "#EF4444" }} text={errors.courseType?.message} />
                  )}
                </View>

                <View style={styles.formGroup}>
                  <CustomText
                    text="Upload a course thumbnail"
                    size="s"
                    textType="text"
                    style={{ color: "#182230" }}
                    weight="ultraBold"
                  />
                  <CustomText
                    text="We'll generate a thumbnail for you if you leave this blank. Recommended size: 800×450"
                    size="xs"
                    style={styles.helperText}
                  />
                  {uploadError && <CustomText text={uploadError} size="xs" style={styles.errorText} />}
                  <View style={styles.uploadContainer}>
                    <Controller
                      control={control}
                      name="courseType"
                      render={({ field: { onChange, value } }) => (
                        <ImageUpload
                          onChange={onChange}
                          value={value}
                          aspectRatio={[16, 9]}
                          allowsEditing={true}
                          onError={(error) => setUploadError(error.message)}
                        />
                      )}
                    />
                  </View>
                </View>

                <View style={styles.formGroup}>
                  <CustomText
                    text="Course Cost"
                    size="s"
                    textType="text"
                    style={{ color: "#182230" }}
                    weight="ultraBold"
                  />
                  <Controller
                    control={control}
                    name="cost"
                    render={({ field: { onChange, value } }) => (
                      <CurrencyInput
                        renderTextInput={(textInputProps) => (
                          <TextInput style={styles.currencyInput} {...textInputProps} />
                        )}
                        value={value}
                        onChangeValue={onChange}
                        delimiter=","
                        separator="."
                        precision={2}
                        minValue={0}
                        keyboardType="numeric"
                        placeholder="Enter amount"
                        prefix="$"
                      />
                    )}
                  />
                  {errors?.cost?.message && (
                    <CustomText size="xs" style={{ color: "#EF4444" }} text={errors.cost?.message} />
                  )}
                </View>

                <View style={styles.formGroup}>
                  <CustomText
                    text="Start Date"
                    size="s"
                    textType="text"
                    style={{ color: "#182230" }}
                    weight="ultraBold"
                  />
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field: { value } }) => (
                      <View style={styles.buttonWrapper}>
                        <DatePickerButton
                          date={value?.toLocaleDateString()}
                          onPress={() => setIsDatePickerVisible(true)}
                          buttonType="start date"
                        />
                      </View>
                    )}
                  />
                  {errors?.startDate?.message && (
                    <CustomText size="xs" style={{ color: "#EF4444" }} text={errors.startDate?.message} />
                  )}
                </View>
                <DatePickerModal
                  locale="en"
                  mode="single"
                  label="Select date"
                  visible={isDatePickerVisible}
                  onDismiss={() => setIsDatePickerVisible(false)}
                  onConfirm={onConfirmDateSelection}
                />
              </>
            )}

            {isDegreesStep && <AddCourseDegreesStep control={control} />}
            {isSecondStep && <AddCourseSecondStep control={control} />}

            <View style={styles.buttonContainer}>
              <CustomButton
                text={isFirstStep ? "Cancel" : "Back"}
                onPress={handleBackOrCancel}
                styleType="secondary"
                size="m"
                style={styles.cancelButton}
                textStyle={styles.cancelButtonText}
                leftIcon={null}
                rightIcon={null}
                disabled={false}
                textProps={{}}
              />
              {(isFirstStep || isDegreesStep) && (
                <CustomButton
                  text={"Next"}
                  onPress={handleNext}
                  styleType="primary"
                  size="m"
                  style={styles.addButton}
                  textStyle={styles.addButtonText}
                  leftIcon={null}
                  rightIcon={null}
                  disabled={false}
                  textProps={{}}
                />
              )}
              {isSecondStep && (
                <CustomButton
                  text={"Add Course"}
                  onPress={handleSubmit(handleFormSubmit)}
                  styleType="primary"
                  size="m"
                  style={!isValid || isSubmitting ? { backgroundColor: "#ccc" } : styles.addButton}
                  textStyle={styles.addButtonText}
                  leftIcon={null}
                  rightIcon={null}
                  disabled={!isValid || isSubmitting}
                  textProps={{}}
                />
              )}
            </View>
          </View>
        </ScrollView>
      </CustomModal>
      <NewCustomAxioActionModal
        visible={modalState.state === AddCourseModalState.ADD_CONFIRMATION}
        onClose={() => setModalState({ state: AddCourseModalState.CLOSED })}
        modalTexts={modals.addConfirmation}
        contextualText={getValues("courseName")}
      />
      <NewCustomAxioActionModal
        visible={modalState.state === AddCourseModalState.ADD_ERROR}
        onClose={() => setModalState({ state: AddCourseModalState.CLOSED })}
        modalTexts={modals.addError}
      />
    </>
  );
};

const styles = StyleSheet.create({
  modalScrollView: {
    flex: 1,
    width: "100%",
  },
  container: {
    padding: 24,
    width: "100%",
  },
  formGroup: {
    marginBottom: 24,
  },
  helperText: {
    color: "#6B7280",
    marginBottom: 8,
  },
  input: {
    width: "100%",
  },
  currencyInput: {
    borderWidth: 1,
    borderColor: "#D1D5DB",
    color: "#1F2937",
    fontSize: 14,
    borderRadius: 8,
    width: "100%",
    padding: 10,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 12,
    marginTop: 8,
  },
  cancelButton: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "#D1D5DB",
    borderRadius: 6,
    paddingHorizontal: 16,
  },
  addButton: {
    // backgroundColor: "#4F46E5",
    borderRadius: 6,
    paddingHorizontal: 16,
  },
  cancelButtonText: {
    color: "#4B5563",
  },
  addButtonText: {
    color: "#FFFFFF",
  },
  dropdown: {
    width: "100%",
    height: 40,
    backgroundColor: "white",
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#D1D5DB",
    paddingHorizontal: 12,
  },
  dropdownError: {
    borderColor: "#EF4444",
  },
  dropdownText: {
    fontSize: 14,
    color: "#111827",
    textAlign: "left",
  },
  dropdownList: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#D1D5DB",
    backgroundColor: "white",
  },
  dropdownRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    paddingHorizontal: 12,
    paddingVertical: 8,
    fontSize: 16,
  },
  dropdownRowText: {
    fontSize: 14,
    color: "#111827",
  },
  dropdownIcon: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderLeftWidth: 5,
    borderRightWidth: 5,
    borderTopWidth: 5,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderTopColor: "#6B7280",
  },
  dropdownIconUp: {
    transform: [{ rotate: "180deg" }],
  },
  errorText: {
    color: "#EF4444",
    marginTop: 4,
  },
  dropdownButton: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  dropdownButtonText: {
    color: "#111827",
    fontSize: 14,
  },
  dropdownPlaceholder: {
    color: "#6B7280",
    fontSize: 14,
  },
  uploadContainer: {
    marginTop: 2,
  },
  buttonWrapper: {
    width: 160,
  },
});

export default AddCourseModal;
